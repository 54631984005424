import React, { useEffect } from "react";
import { DatePicker, DesktopDatePicker } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import TriadentStepper from "../components/TriadentStepper";
import { useNavigate, useLocation } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useOutletContext } from "react-router-dom";
import NextButton from "../components/NextButton";
import BackSubmit from "../components/BackSubmit";
import { OnLastSubmitGegevens } from "../components/OnLastSubmit";
import OnNextSubmit from "../components/OnNextSubmit";

import Pagination from "../components/Pagination";
import { klachtCompProps } from "../types/redux";
import { praktijkinfo } from "../types/praktijkinfo";

let loading = false;
const setLoading = (bool: boolean) => {
  loading = bool;
};

interface PersoongegevensProps extends klachtCompProps {}

const Persoonsgegevens: React.FC<PersoongegevensProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Go back to klacht if there's no gegevens
  useEffect(() => {
    if (is_spoed_praktijk && !("klacht" in props.formData)) {
      navigate("/" + location.search);
    }
  }, []);

  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setError,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: props.formData.persoonsgegevens,
  });

  const theme = useTheme();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));

  const onLastSubmit = OnLastSubmitGegevens(setLoading, props.formData);

  const onNextSubmit = OnNextSubmit(props.dispatch, {}, "/onderdelen", true);

  const praktijkInfo: praktijkinfo = useOutletContext();
  if (!praktijkInfo) {
    return <></>;
  }
  var is_spoed_praktijk = praktijkInfo["is_spoed_praktijk"];
  const vanuit_dashboard = new URLSearchParams(window.location.search).has(
    "vanuit_dashboard"
  );
  const spoed_vanuit_dashboard = is_spoed_praktijk && vanuit_dashboard;

  const onSubmit = is_spoed_praktijk ? onLastSubmit : onNextSubmit;

  const error_str = Object.keys(errors)
    .map((e) => t(e))
    .join(", ")
    .replaceAll("_", " ")
    .replace(/,(?=[^,]*$)/, " en");
  let error_message =
    Object.keys(errors).length == 1
      ? `${t("Het veld")} ${t(Object.keys(errors)[0])} ${t(
          "is niet goed ingevuld"
        )}`
      : `${t("De velden")} ${error_str} ${t("zijn niet goed ingevuld")}`;
  error_message +=
    errors.geboortedatum &&
    watch("geboortedatum") &&
    watch("geboortedatum").getFullYear() < 1900
      ? `.  ${t(
          "Vul alsjeblieft een viercijferige geboortejaar in bij de geboortedatum"
        )}.`
      : "";

  const testGeboortedatum = (datum: Date) =>
    (spoed_vanuit_dashboard && bsn_ingevuld) || datum.getFullYear() >= 1900;

  if (watch("spoed") === "ja") {
    navigate("/spoed" + location.search);
  }
  var bsn_ingevuld = watch("bsn") !== "" && watch("bsn") !== undefined;
  return (
    <React.Fragment>
      <TriadentStepper onderdeel={"gegevens"} />

      <ErrorMessage
        errors={errors}
        name={Object.keys(errors)[0]}
        render={({ message }) => (
          <p className={"error-message"}>{error_message}</p>
        )}
      />

      <Typography>
        {t("Vul de volgende gegevens in voor een spoedafspraak")}:
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid mt={2} container>
          {praktijkInfo && !is_spoed_praktijk && (
            <Grid item xs={12} mt={1}>
              <FormControl component="fieldset" error={!!errors.spoed}>
                <FormLabel component="legend">
                  {t(
                    "Is er sprake van een uitgevallen tand, trauma of nabloeding?"
                  )}
                </FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="spoed"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="ja"
                          control={<Radio />}
                          label={t("Ja")}
                        />
                        <FormControlLabel
                          value="nee"
                          control={<Radio />}
                          label={t("Nee")}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          )}
          {is_spoed_praktijk && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={!!errors.bsn}
                variant="standard"
                label={t("BSN")}
                autoComplete="bsn"
                InputProps={register("bsn", {
                  required: false,
                  pattern: /^[0-9]{9}$/,
                })}
              />
            </Grid>
          )}
          <Grid item xs={6} pr={1}>
            <TextField
              error={!!errors.voornaam}
              InputProps={register("voornaam", {
                required: !spoed_vanuit_dashboard,
              })}
              variant="standard"
              label={t("Voornaam")}
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={6} pl={1}>
            <TextField
              error={!!errors.achternaam}
              InputProps={register("achternaam", {
                required: !spoed_vanuit_dashboard,
              })}
              variant="standard"
              label={t("Achternaam")}
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
          {(!new URLSearchParams(window.location.search).has(
            "vanuit_dashboard"
          ) ||
            is_spoed_praktijk) && (
            <Grid item xs={12}>
              <TextField
                required={spoed_vanuit_dashboard}
                fullWidth
                error={!!errors.email}
                variant="standard"
                label={t("E-mailadres")}
                autoComplete="email"
                InputProps={register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </Grid>
          )}

          {(!new URLSearchParams(window.location.search).has(
            "vanuit_dashboard"
          ) ||
            is_spoed_praktijk) && (
            <Grid item xs={12}>
              <TextField
                required={spoed_vanuit_dashboard}
                fullWidth
                error={!!errors.telefoonnummer}
                variant="standard"
                label={t("Telefoonnummer")}
                InputProps={register("telefoonnummer", {
                  required: true,
                  pattern: /^[0-9\-]{2,}$/,
                })}
                autoComplete="tel"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: spoed_vanuit_dashboard && !bsn_ingevuld,
                validate: testGeboortedatum,
              }}
              name="geboortedatum"
              render={({ field: { value, onChange } }) => (
                <DesktopDatePicker
                  disableOpenPicker={is_small_screen}
                  inputFormat="dd/MM/yyyy"
                  label={t("Geboortedatum")}
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required={spoed_vanuit_dashboard && !bsn_ingevuld}
                      fullWidth
                      variant="standard"
                      {...params}
                      autoComplete="bday"
                      error={!!errors.geboortedatum}
                      helperText={params?.inputProps?.placeholder}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {praktijkInfo && !is_spoed_praktijk && (
            <Grid item xs={12} mt={1}>
              <FormControl component="fieldset" error={!!errors.ingeschreven}>
                <FormLabel component="legend">
                  {t("Bent u momenteel patient bij ons?")}
                </FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="ingeschreven"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="ja"
                          control={<Radio />}
                          label={t("Ja")}
                        />
                        <FormControlLabel
                          value="nee"
                          control={<Radio />}
                          label={t("Nee")}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          )}

          {is_spoed_praktijk && (
            <Grid item xs={12} mt={1}>
              <FormControl
                component="fieldset"
                error={!!errors.verder_medicatie}
              >
                <FormLabel required={spoed_vanuit_dashboard} component="legend">
                  {t(
                    "Gebruikt u verder nog medicatie? Zo ja, neem een medicatieoverzicht mee naar uw afspraak!"
                  )}
                </FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="verder_medicatie"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="ja"
                          control={<Radio />}
                          label={t("Ja")}
                        />
                        <FormControlLabel
                          value="nee"
                          control={<Radio />}
                          label={t("Nee")}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          )}

          {is_spoed_praktijk && (
            <Grid item xs={12} mt={1}>
              <FormControl
                component="fieldset"
                error={!!errors.via_eigen_tandarts}
              >
                <FormLabel required={spoed_vanuit_dashboard} component="legend">
                  {t("Hoe bent u bij ons terechtgekomen?")}
                </FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="via_eigen_tandarts"
                  render={({ field }) => {
                    return (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="ja"
                          control={<Radio />}
                          label={t("Via eigen tandarts")}
                        />
                        <FormControlLabel
                          value="nee"
                          control={<Radio />}
                          label={t("Anders")}
                        />
                      </RadioGroup>
                    );
                  }}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        {is_spoed_praktijk ? (
          <Pagination loading={loading} submit={true} />
        ) : (
          <NextButton />
        )}
      </form>
    </React.Fragment>
  );
};

function mapStateToProps(state: any) {
  return { formData: state.formData };
}

export default connect(mapStateToProps)(Persoonsgegevens);
